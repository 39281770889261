<template>
  <div>
    <NavigationBar
      titel="Abfragen"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard hintID="Verfuegbarkeit_Abfragen_1_V1" text="Text">
      <template v-slot:content>
        <p class="body-2">
          Bevorstehende Abfragen werden hier zusammen mit den wichtigsten
          Informationen angezeigt. Zwei verschiedene Ansichten geben einen
          Überblick über die notwendigen Daten: In der
          <v-icon dense>mdi-view-sequential-outline</v-icon> kompakten Ansicht
          werden nur wichtigen Daten zur Abfrage angezeigt. Die
          <v-icon dense>mdi-table</v-icon> Tabelle listet die Abfragen mit
          Sortierfunktionen auf.
        </p>

        <p class="body-2">
          Folgende Chips und Farben kennzeichnen deine Rückmeldung
          übersichtlich:
        </p>
        <div>
          <v-chip
            small
            color="green lighten-4"
            class="font-weight-medium green--text text--darken-4 mr-1 mt-1"
            >Beantwortet</v-chip
          >
          <v-chip
            small
            color="orange lighten-4"
            class="font-weight-medium orange--text text--darken-4 mr-1 mt-1"
            >Rückmeldung unvollständig</v-chip
          >
          <v-chip
            small
            color="red lighten-4"
            class="font-weight-medium red--text text--darken-4 mr-1 mt-1"
            >Unbeantwortet</v-chip
          >
        </div>
      </template>
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-calendar-account" title="Aktive Abfragen">
                <template v-slot:actions>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="
                          $store.state.organization.activeOrganization.config
                            .availability.showAllRequestsInList ||
                          checkIfSupport ||
                          checkIfItService
                        "
                        outlined
                        :color="$vuetify.theme.dark ? '' : 'white'"
                        v-bind="attrs"
                        v-on="on"
                        :class="[
                          'mr-4',
                          'hidden-sm-and-down',
                          'v-btn-toggle',
                          $vuetify.theme.dark
                            ? ''
                            : 'grey--text text--darken-1',
                        ]"
                        style="
                          border-color: rgba(0, 0, 0, 0.12) !important;
                          border-width: 1px !important;
                        "
                      >
                        <span>
                          <v-icon left small>mdi-calendar-filter</v-icon>
                          {{ requestsFilter }}
                        </span>
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list nav>
                      <v-list-item-group v-model="requestsFilter" mandatory>
                        <v-list-item link dense value="Alle">
                          <v-list-item-icon>
                            <v-icon dense>mdi-all-inclusive</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Alle Abfragen anzeigen</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item link dense value="Eigene">
                          <v-list-item-icon>
                            <v-icon dense>mdi-filter</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            >Nur eigene Abfragen</v-list-item-title
                          >
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-btn-toggle dense v-model="helpers.displayMode" mandatory>
                    <v-btn outlined icon value="list">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-view-sequential-outline</v-icon
                      >
                    </v-btn>
                    <v-btn depressed outlined icon value="table">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div v-if="helpers.displayMode === 'list'" class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line, divider, card-heading, list-item-two-line"
                ></v-skeleton-loader>
                <v-data-iterator
                  v-else
                  :items="requestsOutput"
                  item-key="meta.id"
                  sort-by="start.timestamp"
                  :sort-desc="false"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  no-data-text="Keine Abfragen vorhanden"
                >
                  <template v-slot:header>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            $store.state.organization.activeOrganization.config
                              .scheduling.showAllRequestsInList ||
                            checkIfSupport ||
                            checkIfItService
                          "
                          :color="$vuetify.theme.dark ? '' : 'white'"
                          v-bind="attrs"
                          v-on="on"
                          class="mb-4 mt-n2 hidden-md-and-up"
                          block
                          depressed
                          elevation="1"
                        >
                          <span>
                            <v-icon left small>mdi-calendar-filter</v-icon>
                            {{ requestsFilter }}
                          </span>
                          <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list nav>
                        <v-list-item-group v-model="requestsFilter" mandatory>
                          <v-list-item link dense value="Alle">
                            <v-list-item-icon>
                              <v-icon dense>mdi-all-inclusive</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              >Alle Abfragen anzeigen</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item link dense value="Eigene">
                            <v-list-item-icon>
                              <v-icon dense>mdi-filter</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title
                              >Nur eigene Abfragen</v-list-item-title
                            >
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:default="props">
                    <v-card>
                      <v-list>
                        <div
                          v-for="request in props.items"
                          :key="request.meta.id"
                        >
                          <v-list-item
                            link
                            :to="{
                              name: 'availability-requests-details-tab-general',
                              params: { itemId: request.meta.id },
                            }"
                          >
                            <v-list-item-content>
                              <v-list-item-title
                                class="subtitle-1 font-weight-medium mb-1"
                              >
                                <span
                                  >{{ request.title }} –
                                  {{ request.type.title }}</span
                                >
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <v-chip
                                  v-if="!userIsInvited(request)"
                                  small
                                  color="purple lighten-4"
                                  class="font-weight-medium purple--text text--darken-4 my-1"
                                  >Teilnahmebeschränkt</v-chip
                                >
                                <v-chip
                                  v-else-if="responseIsComplete(request)"
                                  small
                                  color="green lighten-4"
                                  class="font-weight-medium green--text text--darken-4 my-1"
                                  >Beantwortet</v-chip
                                >
                                <v-chip
                                  v-else-if="
                                    request.timeSlots.data.length >
                                    request.userResponse.status.length
                                  "
                                  small
                                  color="orange lighten-4"
                                  class="font-weight-medium orange--text text--darken-4 my-1"
                                  >Rückmeldung unvollständig</v-chip
                                >
                                <v-chip
                                  v-else
                                  small
                                  color="red lighten-4"
                                  class="font-weight-medium red--text text--darken-4 my-1"
                                  >Unbeantwortet</v-chip
                                >
                                <!-- <response-statistics-card
                                  v-else
                                  xSmall
                                  marginClass="my-1"
                                  :totalConfirmations="1"
                                  :totalIfNecessary="3"
                                  :totalCancellations="5"
                                  :totalNotInTown="4"
                                  class=""
                                ></response-statistics-card> -->

                                <p style="line-height: 1.6" class="mb-0">
                                  <span
                                    ><v-icon small left
                                      >mdi-calendar-range</v-icon
                                    >{{
                                      request.timeSlots.start.formattedTimestamp
                                    }}
                                    -
                                    {{
                                      request.timeSlots.end.formattedTimestamp
                                    }} </span
                                  ><br />
                                  <span v-if="request.location.name">
                                    <v-icon small left>mdi-map-marker</v-icon
                                    >{{ request.location.name }}<br
                                  /></span>
                                  <v-icon small left
                                    >mdi-calendar-multiselect</v-icon
                                  >{{ request.timeSlots.data.length }}
                                  Slots
                                </p>
                                <!-- <v-chip
                                  v-if="request.config.attendance.isMandatory"
                                  x-small
                                  class="mt-2 mr-2 font-weight-medium red--text text--darken-4"
                                  color="red lighten-4"
                                  ><v-icon x-small left>mdi-alert-circle</v-icon
                                  >Anwesenheitspflicht</v-chip
                                > -->
                                <v-chip
                                  v-if="showResponsesLocked(request)"
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left>mdi-lock</v-icon
                                  >Rückmeldungen gesperrt</v-chip
                                >
                                <v-chip
                                  v-if="showResponsesAreBinding(request)"
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left
                                    >mdi-check-decagram</v-icon
                                  >Rückmeldung verbindlich</v-chip
                                >
                                <v-chip
                                  v-if="
                                    request.config.responses.deadline.isCustom
                                  "
                                  x-small
                                  depressed
                                  class="mt-2 mr-2 font-weight-medium"
                                  ><v-icon x-small left>mdi-clock-alert</v-icon
                                  >Veränderte Rückmeldefrist</v-chip
                                >
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action
                              v-if="helpers.displayMode === 'list'"
                            >
                              <v-btn
                                color="red darken-1"
                                icon
                                :to="{
                                  name: 'availability-requests-details-tab-general',
                                  params: { itemId: request.meta.id },
                                }"
                              >
                                <v-icon large> mdi-chevron-right </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>

                          <v-divider></v-divider>
                        </div>
                      </v-list>
                    </v-card>
                  </template>
                </v-data-iterator>
              </div>
              <div v-else class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="table"
                ></v-skeleton-loader>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="requestsOutput"
                  item-key="meta.id"
                  multi-sort
                  sort-by="timeSlots.start.timestamp"
                  :sort-desc="false"
                  :footer-props="footerProps"
                  :items-per-page="25"
                  class="elevation-2"
                  max-height="800px auto"
                  no-data-text="Keine Abfragen vorhanden"
                  @click:row="openItem"
                >
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      v-if="responseIsComplete(item)"
                      small
                      color="green lighten-4"
                      class="font-weight-medium green--text text--darken-4 my-1"
                      >Beantwortet</v-chip
                    >
                    <v-chip
                      v-else-if="
                        item.timeSlots.data.length >
                        item.userResponse.status.length
                      "
                      small
                      color="orange lighten-4"
                      class="font-weight-medium orange--text text--darken-4 my-1"
                      >Rückmeldung unvollständig</v-chip
                    >
                    <v-chip
                      v-else
                      small
                      color="red lighten-4"
                      class="font-weight-medium red--text text--darken-4 my-1"
                      >Unbeantwortet</v-chip
                    >
                  </template>

                  <template
                    v-slot:[`item.timeSlots.start.timestamp`]="{ item }"
                  >
                    {{ item.timeSlots.start.formattedTimestamp }}
                  </template>
                  <template v-slot:[`item.timeSlots.end.timestamp`]="{ item }">
                    {{ item.timeSlots.end.formattedTimestamp }}
                  </template>
                  <template v-slot:[`item.slots`]="{ item }">
                    <v-chip label
                      ><v-icon small left>mdi-calendar-multiselect</v-icon
                      >{{ item.timeSlots.data.length }} Slots</v-chip
                    >
                  </template>

                  <template v-slot:no-data>
                    <div
                      style="
                        height: 300px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <p class="subtitle-1">Keine Abfragen vorhanden.</p>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_REQUESTS } from "@/store/action-types.js";
import { AVAILABILITY_REQUESTS_create } from "@/data/permission-types.js";

export default {
  name: "availability-requests",
  components: {
    NavigationBar,
    TippCard,
    SupportTools,
    Subheader,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${AVAILABILITY_REQUESTS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.createItem,
        },
      ],

      footerProps: {
        "items-per-page-options": [15, 25, 50, -1],
      },
      sortDesc: true,
      sortBy: "title",
      requestsFilter: "Eigene",

      helpers: {
        dataIsLoading: false,
        displayMode: false,
        isLoading: false,
      },
      headers: [
        { text: "Status", value: "status", sortable: false },
        { text: "Titel", value: "title" },
        { text: "Abfrageart", value: "type.title" },
        { text: "Startzeitpunkt", value: "timeSlots.start.timestamp" },
        {
          text: "Endzeitpunkt",
          value: "timeSlots.end.timestamp",
          sortable: false,
        },
        { text: "Ort", value: "location.name" },
        { text: "Terminoptionen", value: "slots" },

        // {
        //   text: "Aktionen",
        //   value: "actions",
        //   permissions: [
        //     `${SCHEDULING_EVENTS_create}`,
        //     `${SCHEDULING_EVENTS_update}`,
        //     `${SCHEDULING_EVENTS_delete}`,
        //   ],
        // },
      ],
    };
  },
  computed: {
    code() {
      return [{ title: "requestsMatched", data: this.requestsMatched }];
    },
    availabilityConfig() {
      return this.$store.state.organization.activeOrganization.config
        .availability;
    },
    requests() {
      return this.$store.state.availability.requests;
    },
    requestsStatus() {
      return this.$store.state.availability.requestsStatus;
    },
    requestsMatched() {
      // Add status to requests
      const output = this.requests.map((request) => {
        const userResponse = this.requestsStatus.find(
          (userResponse) =>
            userResponse.userResponse.meta.requestId === request.meta.id
        );
        if (
          userResponse &&
          userResponse.userResponse.status.length === "0" &&
          !this.userIsInvited(request)
        ) {
          userResponse.userResponse.statusCheck = "-0";
        }
        return {
          ...request,
          ...userResponse,

          // deadlineIsExpired: this.deadlineIsExpired(request),
        };
      });
      return output;
    },
    requestsOutput() {
      if (this.requestsFilter == "Alle") {
        return this.requestsMatched;
      } else {
        return this.requestsMatched.filter((request) => {
          const userGroupId =
            this.$store.state.organization.membership.group.id;
          console.log(
            request.config.attendance.allowedAttendeesIds.includes("all"),
            request.config.attendance.allowedAttendeesIds.includes(
              auth.currentUser.uid
            ),
            request.config.attendance.allowedAttendeesIds.includes(userGroupId)
          );
          if (this.userIsInvited(request)) {
            return true;
          } else {
            return false;
          }
        });
      }
    },

    // computedHeaders() {
    //   const headers = this.headers.filter(
    //     (p) => !p.permissions || !this.checkMultiplePermissions(p.permissions)
    //   );
    //   if (this.checkFeatureAvability) {
    //     headers.push({
    //       text: "Rückmeldungen (Vorschau in Beta)",
    //       value: "responses",
    //     });
    //   }
    //   return headers;
    // },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    checkIfItService() {
      return this.$store.getters["organization/checkIfItService"];
    },
  },
  watch: {
    "$store.state.organization.activeOrganization.config.scheduling.showAllRequestsInList":
      {
        immediate: true, // Execute immediately on component creation
        handler(showAllRequestsInListNew) {
          // Check if showAllRequestsInListNew is populated and then execute your code
          if (showAllRequestsInListNew !== null && showAllRequestsInListNew) {
            this.helpers.dataIsLoading = true;

            this.$store
              .dispatch(`${AVAILABILITY}${GET_REQUESTS}`, {
                organizationId: this.$route.params.organizationId,
                withStatus: true,
                query: {
                  onlyOwn: false,
                },
                // withStatus: this.$store.getters["organization/checkPermissionByID"](
                //   `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`
                // )
                //   ? true
                //   : false,
              })
              .then(
                () => {
                  this.helpers.dataIsLoading = false;
                },
                (error) => {
                  console.error(error);
                }
              );
          } else {
            this.helpers.dataIsLoading = false;
            (this.requestsFilter = "Eigene"),
              this.$store
                .dispatch(`${AVAILABILITY}${GET_REQUESTS}`, {
                  organizationId: this.$route.params.organizationId,
                  withStatus: true,
                  query: {
                    onlyOwn: false,
                  },
                })
                .then(
                  () => {
                    this.helpers.dataIsLoading = false;
                  },
                  (error) => {
                    console.error(error);
                  }
                );
          }
        },
      },
  },
  methods: {
    showResponsesAreBinding(request) {
      return this.userIsInvited(request) && request.config.responses.areBinding;
    },
    showResponsesLocked(request) {
      return (
        this.userIsInvited(request) &&
        request.config.responses.areLocked &&
        !request.deadlineIsExpired
      );
    },
    userIsInvited(request) {
      if (request.config.attendance.isLimited) {
        const userGroupId = this.$store.state.organization.membership.group.id;
        if (
          request.config.attendance.allowedAttendeesIds.includes("all") ||
          request.config.attendance.allowedAttendeesIds.includes(
            auth.currentUser.uid
          ) ||
          request.config.attendance.allowedAttendeesIds.includes(userGroupId)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    responseIsComplete(data) {
      const timeSlots = data.timeSlots.data;
      const response = data.userResponse.status;

      if (timeSlots.length !== response.length) {
        // Response can't be complete if there are more time slots than responses
        return false;
      } else {
        // Check if there is a matching response for each time slot by id
        const responseIsComplete = timeSlots.every((timeSlot) => {
          const matchingResponse = response.find(
            (response) => response.id === timeSlot.id
          );
          return matchingResponse;
        });
        return responseIsComplete;
      }
    },
    createItem() {
      this.$router.push({ name: "availability-requests-new" });
    },
    openItem(item) {
      this.$router.push({
        name: "availability-requests-details-tab-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>
